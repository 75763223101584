/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
  font-family: ColfaxWeb, ColfaxWebMedium, Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

body {
  font-family: ColfaxWeb, ColfaxWebRegular, ColfaxWebMedium, Helvetica, Arial, sans-serif;
  font-weight: 400;
}


/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

















/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden {
    display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
    /* Style adjustments for viewports that meet the condition */
}

@media print,
       (min-resolution: 1.25dppx),
       (min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

@font-face {
  font-family: ColfaxWeb;
  src: url(/fonts/ColfaxWebRegularAlt.eot);
  src: url(/fonts/ColfaxWebRegularAlt.eot?#iefix) format("embedded-opentype"), url(/fonts/ColfaxWebRegularAlt.woff2) format("woff2"), url(/fonts/ColfaxWebRegularAlt.woff) format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: ColfaxWebRegular;
  src: url(/Parallebs-Home/src/assets/fonts/ColfaxWebRegularAlt.eot);
  src: url(/Parallebs-Home/src/assets/fonts/ColfaxWebRegularAlt.eot?#iefix) format("embedded-opentype"), url(/Parallebs-Home/src/assets/fonts/ColfaxWebRegularAlt.woff2) format("woff2"), url(/Parallebs-Home/src/assets/fonts/ColfaxWebRegularAlt.woff) format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: ColfaxWeb;
  src: url(/fonts/ColfaxWebRegularItalic.eot);
  src: url(/fonts/ColfaxWebRegularItalic.eot?#iefix) format("embedded-opentype"), url(/fonts/ColfaxWebRegularItalic.woff2) format("woff2"), url(/fonts/ColfaxWebRegularItalic.woff) format("woff");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: ColfaxWeb;
  src: url(/fonts/ColfaxWebMediumAlt.eot);
  src: url(/fonts/ColfaxWebMediumAlt.eot?#iefix) format("embedded-opentype"), url(/fonts/ColfaxWebMediumAlt.woff2) format("woff2"), url(/fonts/ColfaxWebMediumAlt.woff) format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: ColfaxWebMedium;
  src: url(/Parallebs-Home/src/assets/fonts/ColfaxWebMediumAlt.eot);
  src: url(/Parallebs-Home/src/assets/fonts/ColfaxWebMediumAlt.eot?#iefix) format("embedded-opentype"), url(/Parallebs-Home/src/assets/fonts/ColfaxWebMediumAlt.woff2) format("woff2"), url(/Parallebs-Home/src/assets/fonts/ColfaxWebMediumAlt.woff) format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: ColfaxWeb;
  src: url(/fonts/ColfaxWebMediumItalic.eot);
  src: url(/fonts/ColfaxWebMediumItalic.eot?#iefix) format("embedded-opentype"), url(/fonts/ColfaxWebMediumItalic.woff2) format("woff2"), url(/fonts/ColfaxWebMediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic
}

section:not(#aboutus) {
  padding: 50px 0;
}

#intro, #gallery {
  background-color: #f8f9fa;
}

#courses {
  background-image: linear-gradient(#fff,#f7fbfb);
}

.nav-link {
  font-family: ColfaxWeb, ColfaxWebMedium, Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  letter-spacing: .04rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #5f656d;
  padding: 5px 11px;
  display: block;
  opacity: .9;
}

.nav-item {
  margin: auto 15px;
}

h1, h2 {
  font-family: ColfaxWeb, ColfaxWebMedium, Helvetica, sans-serif;
  font-weight: 500;
}

h3 {
  font-family: ColfaxWeb, ColfaxWebMedium, Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  letter-spacing: .04rem;
  line-height: 1.4em;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
}

#aboutus {
  background-color: #42175a;
}

.intro-company {
  background-color: #34368c;
  color: white;
  padding: 50px;
  margin-top: -30px;
  margin-bottom: -30px;
}

@media (min-width: 768px) {
  .intro-company {
    background-color: #f1f1f1;
    color: black;
  }
}

@media (max-width: 767.98px) {
  .intro-company .link-arrows {
    color: #f4c6e3;
  }
}

.intro-example {
  background-color: #42175a;
  padding: 20px 15px;
}


.intro-example figure {
  padding: 20px;
  display: flex;
  justify-content: center;
  margin: 0;
  background-color: white;
}

@media (min-width: 768px) {
  .intro-example .ml {
    margin-left: -60px;
  }
}

.course-info {
  padding: 30px;
  background-color: white;
}

.link-arrows {
  font-family: ColfaxWeb, ColfaxWebMedium, Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  letter-spacing: .04rem;
  line-height: 1.4em;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #42175a;
  display: block;
  transition: filter .2s cubic-bezier(.645,.045,.355,1);
  filter: url(data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><f…ncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter);
  filter: brightness(100%);
}

.common-Button {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  font-family: ColfaxWeb, ColfaxWebMedium, Helvetica, Arial, sans-serif;
  color: white !important;
  text-transform: uppercase;
  letter-spacing: .025em;
  text-decoration: none;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  margin: 10px 10px 10px 0;
}

.common-Button:hover {
  color: white;
  cursor: pointer;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.button-2 {
  background-color: #2ddc82;
}

.button-1 {
  background-color: #6772e5;
}

.button-1:hover {
  background-color: #7795f8;
}

.material-icons {
  transform: translateY(25%);
  margin-right: 7.5px;
}

.course-card {
  display: block;
  background: #fff;
  box-shadow: 0 6px 50px rgba(0,0,0,.06);
  border: 1px solid white;
  border-radius: 15px;
  padding: 3.5rem;
  text-decoration: none;
  position: relative;
  transition: transform .4s cubic-bezier(.4,0,.2,1);
  color: #42175a;
  margin: 20px;
}

.course-card i {
  font-size: 28px;
  margin: 0 10px 10px 0;
}

#aboutus .display-4 {
  font-size: 2.2em;
  line-height: 1.3em;
}

.img-box {
  display: block;
  width: 100%;
  height: 350px;
  margin: 10px 0;
  background-size: cover;
  background-position: center;
}
